<script>
import { getTicketData } from "@/js/helper.js";
export default {
  computed: {
    newtinAuthorizeCentersListEdit() {
      let centersList = [];
      if (
        Object.keys(this.$store.state.Company.company).length > 0 &&
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        centersList = this.$store.state.Company.company.centers.map((item) => {
          return {
            centerName: this.$store.state.User.centerMap[item.center_id].abbrev,
            state: this.$store.state.User.centerMap[item.center_id].state,
            center_id: item.center_id,
            excavator_type: item.excavator_type,
          };
        });
      }
      return centersList;
    },
  },
  methods: {
    async getStates() {
      let states = [];
      let centersList = this.newtinAuthorizeCentersListEdit;

      for (let i = 0; i < centersList.length; i++) {
        const item = centersList[i];
        if (!item) continue;
        const statesStatus = { state: item.state };
        if (statesStatus && this.stateToCenterMap) {
          this.stateToCenterMap[statesStatus.state] = item.centerName;
          this.states.push(statesStatus);
        }
      }
      return states;
    },
    async getPlaces(selectedState, selectedCounty) {
      const bodyObject = {};
      bodyObject.body = {
        state: selectedState,
        county: selectedCounty,
      };
      const placesStatus = await this.$store.dispatch("getPlaces", bodyObject);
      if (placesStatus.status !== "error") {
        return placesStatus;
      }
    },
    async editTicket(element, editItem) {
      await this.$store.commit("setMapData", element.options.geoJson);
      await this.$store.commit(
        "setExcavationDetails",
        element.options.excavationDetails
      );
      await this.$store.commit("setTicketData", element.options.ticketData);
      await this.$store.commit("setCurrentStep", 2);
      await this.$store.commit("setIsEdit", editItem);
      if (element.options.scheduleDetails)
        element.options.scheduleDetails.scheduled_ticket_id =
          element.scheduled_ticket_id;
      await this.$store.commit(
        "setScheduleDetails",
        element.options.scheduleDetails
      );
    },
    async updateTicket(element, editType) {
      element.center = element.centerData.center;
      const ticketResp = await this.$store.dispatch("getTicketById", element);
      element.options = ticketResp ? ticketResp[0].options : {};
      // clear digin info
      if (editType === "Digin") {
        this.removeDiginInfo(element.options);
      }
      await this.$store.commit(
        "setTicketData",
        element.options && element.options.ticketData
          ? element.options.ticketData
          : {}
      );
      await this.$store.commit("setCurrentStep", 2);
      await this.$store.commit("setIsEdit", editType);
      await this.$store.commit("setCenterTicketId", element.center_ticket_id);
      await this.$store.commit("setTicketId", element.ticket_id);
      await this.$store.commit(
        "setExcavationDetails",
        element.options && element.options.excavationDetails
          ? { ...element.options.excavationDetails, ...element.utahDetails }
          : {}
      );
      await this.$store.commit(
        "setMapData",
        element.options && element.options.geoJson
          ? element.options.geoJson
          : {}
      );
    },
    async setTicketData(element) {
      const states = await this.getStates(element.options.ticketData.center);
      element.options.ticketData.states = states;
      await this.$store.commit("setTicketData", element.options.ticketData);
    },
    forceSuspend(centerId) {
      let allCentersMap = this.$store.state.User.centerMap;
      if (Object.keys(allCentersMap).length > 0) {
        let center = allCentersMap[centerId];
        return center.options.newtin2?.suspend_converts?.length > 0;
      }
      return false;
    },
    async submitTicket(item, usersMap) {
      const user = usersMap[item.sender_user_id];
      const company = this.$store.state.Company.company;
      const ticketDetails = item.options.ticketData;
      const excavationDetails = item.options.excavationDetails;
      const features = item.options.geoJson.features;
      const apiData = getTicketData(
        ticketDetails,
        excavationDetails,
        features,
        user,
        company
      );
      const payload = {
        apiData: apiData,
        excavationDetails: excavationDetails,
        geoJson: item.options.geoJson,
        ticketData: ticketDetails,
        sender_user_id: item.sender_user_id,
        features,
        user,
        company,
      };
      let postRoutine = this.forceSuspend(apiData.center_id)
        ? "suspendTicket"
        : "createTicket";
      // Dispatch the action to the store
      const ticketResp = await this.$store.dispatch(postRoutine, payload);
      if (ticketResp.status !== "error") {
        let approvedObject = {};
        approvedObject.approval_id = item.approval_id;
        approvedObject.center_ticket_id = `${ticketResp[0].center_ticket_id} - ${ticketResp[0].revision}`;
        approvedObject.priority = ticketResp[0].priority;
        approvedObject.status = "approved";
        const approvalData = await this.$store.dispatch(
          "updateApproval",
          approvedObject
        );
        if (approvalData.status !== "error") {
          this.$emit("closeDialog");
        }
        this.isTicketSubmitted = true;
        this.closeDialog();
        this.ticketNumber = `${ticketResp[0].center_ticket_id} - ${ticketResp[0].revision}`;
      } else {
        this.errorCode = ticketResp.errorCode.toString();
        this.errorMessage = ticketResp.message;
        this.isError = true;
      }
    },
    removeDiginInfo(options) {
      const keysToRemove = [
        "digin_blowing",
        "digin_other",
        "digin_spill",
        "digin_water",
        "digin_sewer",
        "digin_phone",
        "digin_gas",
        "digin_elec",
        "digin_catv",
        "digin_fiber",
        "digin_comm",
        "membersToAdd",
      ];

      keysToRemove.forEach((key) => {
        delete options.excavationDetails[key];
      });
    },
  },
};
</script>
